import React from 'react'
import PropTypes from 'prop-types'
import styles from './style'

const content = ({ 
    children,
    row,
    col,
    classCss,
    justifyStart, 
    justifyCenter, 
    justifyEnd,
    alignStart, 
    alignCenter, 
    alignEnd,
    spaceBetween,
    padded,
    paddingX,
    paddingY,
    backColor,
    style,
    
}) => {
    return (
        <div style={{
            ...styles.container,
            ...(row ? styles.row : {}),
            ...(col ? styles.column : {}),
            ...(justifyCenter ? styles.justifyCenter : {}),
            ...(alignCenter ? styles.alignCenter : {}),
            ...(spaceBetween ? styles.spaceBetween : {}),
            ...(backColor ? { backgroundColor: backColor } : {}),
            ...(style || {})
        }}
        className={classCss}
        >
            {children}
        </div>
    )
}

content.propTypes = {
    row: PropTypes.bool,
    col: PropTypes.bool,
    justifyStart: PropTypes.bool,
    justifyCenter: PropTypes.bool, 
    justifyEnd: PropTypes.bool,
    alignStart: PropTypes.bool, 
    alignCenter: PropTypes.bool, 
    alignEnd: PropTypes.bool,
    spaceBetween: PropTypes.bool,
    padded: PropTypes.bool,
    paddingX: PropTypes.bool,
    paddingY: PropTypes.bool,
    backColor: PropTypes.string,
    classCss:  PropTypes.string
}

content.defaultProps = {
    row: false,
    col: true,
    justifyStart: true,
    justifyCenter: false,
    justifyEnd: false,
    alignStart: true,
    alignCenter: false,
    alignEnd: false,
    spaceBetween: false,
    padded: false,
    paddingX: false,
    paddingY: false,
    backColor: null,
    classCss: null
}

export default content