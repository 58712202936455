import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import {
    Content, Spacer
} from 'components'
import { Redirect  } from "react-router-dom";
import AuthService from 'services/auth_service'

import PrimaryButton from 'components/buttons/primaryButton/index'
import SecondaryButton from 'components/buttons/secondaryButton/index'
import PrimaryLink from 'components/links/primaryLink/index'
import PrimaryText from 'components/texts/primaryText/index'
import SecondaryText from 'components/texts/secondaryText/index'
import styles from './style'
import inputs from 'style/inputs/text/index'
import bannerAsset from 'assets/Logo-md.svg'
import fb from 'assets/facebook-logo.png'
import gl from 'assets/google-logo.png'
import useAuth from 'hooks/useAuth';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Spinner from 'components/spinner/index'
import { isEmpty } from 'lodash';
import AuthContext from 'contexts/AmplifyContext';



const SignIn = () => {
    const [password, setPassword] = useState(false)// Estado que muestra los imputs para cambiar contrasena
    const [codePassword, setCodePassword] = useState('') // aqui se guarda el codigo ingresado para cambiar contrasena  
    const [newpassword, setNewPassword] = useState('')// Aqui va la nueva contrasena
    const [confirmNewpassword, setConfirmNewPassword] = useState('')// Aqui va la confirmacion de la contrasena
    const [signUp, setSignUp] = useState(false)
    const [signIn, setSignIn] = useState(false)
    const [error, setError] = useState(null)
    const [dataOrganization, setDataOrganization] = useState('')
    const [user, setUser] = useState({username: '', password: '', email: '', authCode: ''})
    const [code, setCode] = useState(false)
    const [lockEmail, setLock] = useState(false)
    let history = useHistory();

    const { user: userFromContext } = React.useContext(AuthContext);

    const {login, register, verifyCode, resendCode, passwordReset, passwordRecovery} = useAuth()

    const [loading, setLoading] = useState(false)

    const changeInput = (e) => {
        setError(null)
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    function validateEmailUser(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(email).toLowerCase())){
            return true
        }else{
            return false
        };
    }

    function resetStep() {
        setPassword(false)
        setSignIn(false)
        setSignUp(false)
        setCode(false)
        setLock(false)
        setError(null)
        setUser({ username: '', password: '', email: '', authCode: '' })
        localStorage.setItem("userId", "")
        localStorage.setItem("email", "")
    }
    const responseGoogle = async (response) => {
        const response2 = await AuthService.checkUser(response.profileObj.email)

        if(response2.data && response2.success){
            setDataOrganization(response2.data)

            if(response2.data.id !== null){
                localStorage.setItem("userId", response2.data.id)
                localStorage.setItem("email", response2.data.email)
            }

            // iniciar sesion
            if(!response2.data.organization_id){
                history.push("/wizard?userId=" + response2.data.id);
            }else{
                history.push('/dashboard')
            }
        }else {
            // registro en la base de datos
            await AuthService.register(response.profileObj.email).then(res=>{
                setDataOrganization(res.data);
                localStorage.setItem("userId", res.data.id);
                localStorage.setItem("email", res.data.email);
                history.push("/wizard?userId=" + res.data.id);
            })
        }

    }
    const responseFacebook = async (response) => {
        const response2 = await AuthService.checkUser(response.email)
        if(response2.data && response2.success){
            setDataOrganization(response2.data)

            if(response2.data.id != null){
                localStorage.setItem("userId", response2.data.id)
                localStorage.setItem("email", response2.data.email)
            }

            // iniciar sesion
            if(!response2.data.organization_id){
                history.push("/wizard?userId=" + response2.data.id);
            }else{
                history.push('/dashboard')
            }          
        }else {
            // registro en la base de datos
            await AuthService.register(response.email).then(res=>{
                setDataOrganization(res.data);
                localStorage.setItem("userId", res.data.id);
                localStorage.setItem("email", res.data.email);
                history.push("/wizard?userId=" + res.data.id);
            })
        }
    }
    // verifica si el correo ingresado ya esta registrdo o no
    async function checkUser() {
        const response = await AuthService.checkUser(user.username);
        
        if(!!response.data){
            setDataOrganization(response.data)
            setLock(true)
            localStorage.setItem("userId", response.data.id)
            localStorage.setItem("email", response.data.email)
        }                     
        
        return !response.data || !response.success ? setSignUp(true) : setSignIn(true);
    }
    // verifica si el codigo para registrarse es correcto
    async function checkCode(){
        setError(null)
        if(user.authCode !== ''){
           await verifyCode(user.username,user.authCode)
           .then(response=> {
                if(response.status){
                   
                    AuthService.register(user.username)
                        .then((res) => {
                            setSignUp(false);
                            setCode(false);
                            setSignIn(true);
                            setUser({
                                ...user,
                                id: res.data.id,
                                password:''
                            })
                            localStorage.setItem("userId", res.data.id)
                            localStorage.setItem("email", res.data.email)
                        })
                        .catch(err=>{
                            console.log(err)
                        })                    
                }else {
                    setCode(true)
                    setError(response.data)
                }
            })           
        }else{
            setError('Invalid code')
        }
    }

    async function sendRequest() {
        setError(null)

        if(signUp) {
           
            await register(user.username, user.password)
            .then(response=> {
                setDataOrganization(response)
                 if(response.status){
                    setCode(true)
                 }else{
                    setError(response.data)
                 }
                 
                 
             }).catch(err=>{
            
                 setError(err.message)
             })          
        } else if(signIn) {
            await login(user.username, user.password)
           .then(response=> {
                if(response.status) {
                    setSignUp(false);
                    setCode(false);
                    setSignIn(true);
                    setUser({
                        ...user,
                        password:''
                    })
                if (!dataOrganization || !dataOrganization.organization_id || isEmpty(dataOrganization)){
                    history.push("/wizard?userId=" + localStorage.getItem("userId"))
                }else{
                   history.push('/dashboard')
                }
                
                 }else{
                   
                    setError(response.data)
                 }
                
                
            }).catch(err=>{
               
                setError(err.message)
            })
        }
    }

    async function checkCodePassword (){
        setError(null)
        if(newpassword===confirmNewpassword){
            if(newpassword !="" && confirmNewpassword != ""){
                if(codePassword!==''){
                await passwordReset(user.username,codePassword,newpassword)
                .then(response=> {
                        if(response.status){
                                    
                            setSignUp(false);
                            setSignIn(true);
                            setNewPassword(false)
                            setPassword(false)
                            setUser({
                                ...user,
                                password:''
                            })                    

                        }else {
                            setError(response.data)
                        
                            //setCode(false);
                        }
                    })           
                }else{
                    setError('Invalid code')
                }
            }else{
                setError('Invalid values')
            }
        }else{
            setError('Passwords does not match')
        }
    }

    React.useEffect(() => {
        if (userFromContext && userFromContext.organization_id) {
            history.push('/dashboard');
        }
    }, [userFromContext])

    return (
        <div >
            {loading?
                <Spinner></Spinner>
            :
            <Content 
                col
                alignCenter 
                spaceBetween
                style={styles.container}
            >
                <Spacer.Large />
                <Content alignCenter style={styles.noGrow}>
                    <Spacer.Large />
                    <img src={bannerAsset} />
                    <Spacer.Medium />
                    <PrimaryText
                    text={!password?"Welcome to DonorPal.":""}
                    >
                    </PrimaryText>
                    <PrimaryText
                    text={code?"Please enter the code that was sent to your email":password?"Please enter a new password":"Please sign in or sign up below."}
                    >
                    </PrimaryText>
                    <Spacer.Medium />
                    {code?
                    <input 
                        type='text'
                        placeholder={code?"code":"Email or Phone"}
                        disabled={lockEmail}
                        name='authCode'
                        value={user.authCode}
                        onChange={(e)=>{changeInput(e)}}
                        style={{ 
                            ...inputs.textTransparent,
                            outline: 'none !important',
                        }}           
                    />
                    :password?
                    <input 
                        type='password'
                        name='password'
                        value={newpassword}
                        placeholder={"Please enter your new password"  }
                        onChange={(e)=>{setNewPassword(e.target.value)}}
                        style={{ 
                            ...inputs.textTransparent,
                            outline: 'none !important',
                        }}
                    />
                    
                    :
                    <input 
                        type='text'
                        placeholder="Email or Phone"
                        name='username'
                        disabled={lockEmail}
                        value={user.username}
                        onChange={(e)=>{changeInput(e)}}
                        style={{ 
                            ...inputs.textTransparent,
                            outline: 'none !important',
                        }}
                    
                    />}
                    
                    <Spacer.Medium />
                    {(signIn==true || signUp==true)&&!code?
                        <input 
                            type='password'
                            name='password'
                            value={user.password}
                            placeholder={signIn == true? "Please enter your password" : "Please create a password"}
                            onChange={(e)=>{changeInput(e)}}
                            style={{ 
                                ...inputs.textTransparent,
                                outline: 'none !important',
                            }}
                        />
                        :
                        password?
                        <>
                            <input 
                                type='password'
                                name='password'
                                value={confirmNewpassword}
                                placeholder={"Please enter your new password again"  }
                                onChange={(e)=>{setConfirmNewPassword(e.target.value)}}
                                style={{ 
                                    ...inputs.textTransparent,
                                    outline: 'none !important',
                                }}
                            />
                            <Spacer.Medium />
                            <input 
                            type='text'
                            placeholder={"code"}
                            value={codePassword}
                            onChange={(e)=>{setCodePassword(e.target.value)}}
                            style={{ 
                                ...inputs.textTransparent,
                                outline: 'none !important',
                            }}           
                        />
                    </>
                        : null 
                    }
                    
                    <Spacer.Medium />
                    {(signIn==true || signUp==true)?
                        <PrimaryButton  
                            text={signIn == true? "Sign in" :code?"validate code": "Sign up"}
                            onClick= {()=>{code?checkCode():sendRequest()}}
                        >        
                        </PrimaryButton>
                        : password?
                        <PrimaryButton  
                            text="Continue" 
                            onClick= {()=>{checkCodePassword()}}
                        >        
                        </PrimaryButton>
                        :
                        <PrimaryButton  
                            text="Continue" 
                            onClick= {()=>{checkUser()}}
                        >        
                        </PrimaryButton>
                    }
                    <span style={{color:'red', paddingTop:'30px'}}>{error}</span>
                    <Spacer.Medium />
                    <PrimaryLink 
                        text={code?"Resend code":!password?"Forgot password?":null}
                        onClick={async ()=>{
                            if(code){
                                if(user.username!=""){
                                    if(validateEmailUser(user.username)){
                                        try{
                                            await resendCode(user.username)
                                        }catch(err){
                                            setError(err.message)
                                        }
                                    }else{
                                        setError("Invalid format email, please try again")
                                    }
                                }else{
                                    setError('Invlid Email')
                                }
                                
                            }else{
                                setSignIn(false);
                                setSignUp(false);
                                if(user.username!=""){
                                    if(validateEmailUser(user.username)){
                                        try{
                                            await passwordRecovery(user.username)
                                            setPassword(true)
                                            
                                        }catch(err){
                                            setError(err.message)
                                        }
                                    }else{
                                        setError("Invalid format email, please try again")
                                    }
                                }else{
                                    setError('Invalid Email')
                                }
                                
                                
                            }                       

                        }}
                    >
                    </PrimaryLink>
                    {(signIn==true || signUp==true)?
                        <PrimaryLink 
                            text="Back"
                            onClick= {()=>{resetStep()}}
                        >
                        </PrimaryLink>
                        : null
                    }
                    {password==true?
                        <PrimaryLink 
                            text="Back to login"
                            onClick= {()=>{resetStep()}}
                        >
                        </PrimaryLink>
                        : null
                    }
                    
                </Content>
                <Spacer.Large />
                {!password?
                    <Content
                        col
                        alignCenter 
                        spaceBetween 
                    >
                        <SecondaryText
                            text="Or continue with:"
                        >
                        </SecondaryText>
                        <Spacer.Medium />
                        <GoogleLogin
                            clientId="549109699361-piasjki2j3au67q6nsbrv5l3lffdpb95.apps.googleusercontent.com"
                            render={renderProps => (
                                <SecondaryButton
                                text="Google"
                                imgUrl={gl}
                                onClick={renderProps.onClick} disabled={renderProps.disabled}
                                
                            />
                            )}
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={(err)=>{console.log(err)}}
                            cookiePolicy={'single_host_origin'}
                        />
                        
                        <Spacer.Medium />
                        <FacebookLogin
                            appId="918778282076284"
                            fields="name,email,picture"
                            callback={responseFacebook}
                            scope="public_profile, email"
                            render={renderProps => (
                                    <SecondaryButton
                                        text="Facebook"
                                        imgUrl={fb}
                                        onClick={renderProps.onClick}
                                    />                
                            )}
                            onFailure={(err)=>{console.log(err)}}
                        />
                            
                        
                    </Content>
                    :null
                }
            </Content>
            }
        </div>
    )
}

export default SignIn
