import React, { useState, useEffect } from "react";
import { Col, Form, FormGroup, Label, Media } from "reactstrap";
import inputs from "style/inputs/text/index";
import styles from "./style";
import TextTitle from "components/texts/Text-title";
import TextCaption from "components/texts/Text-caption";
import AuthService from "services/auth_service";
import PrimaryButton from "components/buttons/primaryButton/index";
import Spinner from "components/spinner/index"

const Form1 = () => {
  const [data, setData] = useState({
    name: "",
    email: "",

  });
  const [initialData, setInitialData] = useState({
    name: "",
    email: "",

  });
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorPassword, setErrorPassword] = useState(false)
  const [errorName, setErrorName] = useState(false)
  const [showButton, toggleButton] = useState(false)
  const [call1, setCall1] = useState(false);
  const [loading, setLoading] = useState(false)

  async function getUserById() {
    const id = localStorage.getItem("userId")
    const response = await AuthService.getUserById(id);
    setCall1(true)
    return response.length == 0
      ? alert("No info")
      : 
        setInitialData({
            name:response.data.name,
            email:response.data.email,
        }),setData({
          name:response.data.name,
          email:response.data.email,
        })
      ;
  }

  function validateEmailUser(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(String(email).toLowerCase())){
        setErrorEmail(false)
        return true
    }else{
        setErrorEmail(true)
        return false
    };
  }

    function validateName(name) {
        if(name != ''){
            setErrorName(false)
            return true
        }else{
            setErrorName(true)
            return false
        };
        }
    function validatePassword(password) {
        if(password != ''){
            setErrorPassword(false)
            return true
         }else{
            setErrorPassword(true)
            return false
        };
        } 


  function checkForm() {
    var newValues = false;
    Object.keys(data).map((key) => {
      if(data[key] != initialData[key]){
        newValues = true
      }
    })
    newValues == true? toggleButton(true) : toggleButton(false)
  }

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })

  };

  const handleButton = () => {
      
      let responseEmail=validateEmailUser(data.email);
      let responseName=validateName(data.name);
      // let responsePassword = validatePassword(data.password);

      if(responseEmail&&responseName){
         try {
             AuthService.userUpdate({
              id: localStorage.getItem("userId"),
              email: data.email,
              name: data.name
          }).then(res => {
            localStorage.setItem("userName", `${data.name}`)
             // console.log(res)
          })
        }catch (err) {
            console.log(err)
        }
      }
    
  }


  useEffect(() => {
    checkForm()
  }, [data]);

  useEffect(() => {
    setLoading(true)
    getUserById()
  },[]);

  useEffect(() => {
    if(call1 == true){
      setLoading(false)
    }
  }, [call1]);
  return (

    <div>
      {loading? 
        <Spinner></Spinner>
    :

    <Form className="mt-4 position-relative" style={{ width: "100%" }} >
        <FormGroup row className="position-absolute" style={styles.contentSaveButton}>
        {showButton == true?
          <Col className="d-flex justify-content-end">
            <PrimaryButton
              styleBtn={{maxWidth: '58px'}}
              text="Save"
              onClick={() => { handleButton()}}
            />
          </Col>
          :null
        }
        
      </FormGroup>
      <FormGroup row>
        <Label style= {{ ...styles.label,}} for="name" sm={12}>
          Name
        </Label>
        <Col sm={12}>
          <input
            type="text"
            style={{
              ...inputs.textForm,
              outline: "none !important",
            }}
            onChange={handleInputChange}
            name="name"
            value={data.name}
          />
          { errorName ?
          <span style= {{
              color: 'red',
              padding: '10px'
          }}> {'this field cannot be blank'}</span> : null}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label style= {{ ...styles.label,}} for="emailAddress" sm={12}>
          Email address
        </Label>
        <Col sm={12}>
          <input
            type="text"
            style={{
              ...inputs.textForm,
              outline: "none !important",
            }}
            onChange={(e)=>{handleInputChange(e)}}
            name="email"
            value={data.email}
          />
           { errorEmail ?
          <span style= {{
              color: 'red',
              padding: '10px'
          }}> {'Invalid email'}</span>:null}
        </Col>
      </FormGroup>
      {/* <FormGroup row>
        <Label for="password" sm={12}>
          Password
        </Label>
        <Col sm={12}>
          <input
            type="password"
            style={{
              ...inputs.textForm,
              outline: "none !important",
            }}
            onChange={(e)=>{handleInputChange(e)}}
            name="password"
            value={data.password}
          />
           { errorPassword ?
          <span style= {{
              color: 'red',
              padding: '10px'
          }}> {'this field cannot be blank'}</span>:null}
        </Col>
      </FormGroup> */}
    </Form>
      }
    </div>
  );
};

export default Form1;
