import axios from "axios";

const apiBasePath = process.env.REACT_APP_DEV_API_URL_LOCAL

const AuthService = {
    checkUser: async function(email) { 
        const response = await axios.get(`${apiBasePath}/user/${email}/email`);
        return response.data;
    },

    getUserById: async function(id) { 
        const response = await axios.get(`${apiBasePath}/user/${id}`);
        return response.data;
    },

    getUserBank: async function(token) { 
        const response = await axios.post(`${apiBasePath}/user/banks`, {access_token: token});
        return response.data;
    },

    register: async function(email) {
        const response = await axios.post(`${apiBasePath}/user`, {email: email});
        return response.data;
    },
   
    userUpdate : async function(data) { 
        const response = await axios.put(`${apiBasePath}/user/${data.id}`,data);
        return response.data;
    },
};

export default AuthService;
