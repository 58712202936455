import axios from "axios";

const apiBasePath = process.env.REACT_APP_DEV_API_URL_LOCAL

const UserService = {
    fetchUserData: async function(id) { 
        const response = await axios.get(`${apiBasePath}/user/${id}`);
        return response.data;
    },
    fetchUsersAll: async function(id) { 
        const response = await axios.get(`${apiBasePath}/user`);
        return response.data;
    },
    updateUser: async function(payload) {
        const response = await axios({
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            url: `${apiBasePath}/user/${payload.id}`,
            data: JSON.stringify(payload)   
        })
        return response.data;
    }
};

export default UserService;
