import colors from '../../../style/colors'

export default {
    text: {
        color: colors.primary,
        textAlign: 'center',
        fontFamily: 'Circular Std',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '24px',
        letterSpacing: '0px',
        fontFamily: 'inherit',  
    }
}