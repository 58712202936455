import colors from '../../../style/colors'

export default {
    button: {
        backgroundColor: colors.quaternary,
        color: colors.secondary,
        width: '240px',
        height: '64px', 
        paddingTop: '18',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '32px',
        borderWidth: '0px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center'
        // boxShadow: 'rgba(0,0,0,0.5) 5px 5px 5px',
        //filter: "drop-shadow(0px 32px 64px rgba(12, 1, 254, 0.1)) drop-shadow(0px 2px 4px rgba(12, 1, 254, 0.1))"
        
    },
    img: {
        verticalAlign: 'middle',
        marginRight: '12px',
        width: '24px',
    },

    div: {
        display: 'flex',
        flexDirection: 'column'
    },

    small: {
        fontFamily: 'Circular Std',
        textAlign: 'left',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '-0.03em'
    },

    big: {
        fontFamily: 'Circular Std',
        textAlign: 'left',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '-0.03em'
    },
}