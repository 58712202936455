import React, {useState} from 'react'
import './style.css';
import PrimaryButton from 'components/buttons/primaryButton/index'


const Modal = ({ handleClose, show, stripe }) => {

    const showHideClassName = show==true ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {stripe == true?
                    <span className="success">You Stripe account has been connected!.</span>
                    :
                    <span className="error">The account is not correctly verify.</span>
                }
                <section className="modal-btn">
                    <PrimaryButton  
                        text="Close"
                        classCss="btn"
                        onClick= {handleClose}
                    ></PrimaryButton>
                </section>
            </section>
        </div>
    );
};

export default Modal