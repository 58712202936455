import React, { useState, useEffect } from 'react'
import {
    Content, Spacer
} from 'components/index'
import { useParams } from "react-router-dom";
import Summary from 'components/elements/summary/index'
import Table from 'components/elements/table/index'
import Menu from 'views/menu/index.js'
import './style.css'
import bannerAsset from 'assets/Logo.svg';
import Spinner from "components/spinner/index"
import TextHg from 'components/texts/Text-hg'

import OrganizationService from 'services/organization_service'
import UserService from 'services/user_service'
import { useHistory } from "react-router-dom";
import StripeService from 'services/stripe_service'

import Modal from 'views/Onboarding/modal/index';
import dayjs from 'dayjs';

const Dashboard = () => {
    const [loading, setLoading] = useState(false)
    const [errorStripe, setErrorStripe] = useState("")
    const [noStripe, setNoStripe] = useState(false)
    const [openModal, setModal] = useState(false)
    const [logo, setLogo] = useState('')
    const [orgName, setOrgName] = useState('')
    const [userName, setUserName] = useState('')
    const [tableData, setTableData] = useState([])
    const [user, setUser] = useState();
    const [summaryData, setSummary] = useState({
        totalDonations : '0',
        numberDonations : '0',
        totalMonth : '0'
    })
    const [userId, setId] = useState(null) 
    let history = useHistory(); 
    const { access } = useParams()
    const [call1, setCall1] = useState(false);
    const [call2, setCall2] = useState(false);
    
    const headers = [{
        name: 'Total donations'},{
        name: 'Number of donations'},{ 
        name: 'Current monthly donors'
    }]

    const tableHeaders = [{
        name: 'name',
        size: 'colLg'
    },{
        name: 'date',
        size: 'colLg'
    },{ 
        name: 'recurring',
        size: 'colMd'
    },{ 
        name: 'amount',
        size: 'colMd'
    }]



    function processData(data) {
        
        const totalD = data.length
        if(totalD > 0){
            var totalAmount = 0
            var dates = []
            var recentDate = '' 
            var tableRows = []

            data.forEach(element => {
                dates.push(element.created_at)
                var fullName = `${element.firstname} ${element.lastname}`

                totalAmount += +(Number(element.amount)  / 1.029 - 0.29).toFixed(2)

                var amount = currencyFormat(+element.amount)
                var rec = ''
                element.donation_type_slug === 'one.time' || !element.donation_type_slug ? rec = 'No' : rec = 'Yes'
                tableRows.push({
                    name: fullName,
                    date: element.created_at,
                    recurring: rec,
                    amount: amount
                })
            });
            let sortedRows = tableRows.sort((a, b) => new Date(b.date?.localeCompare(a.date)));
            sortedRows.forEach(element => { 
                element.date = dayjs(element.date).format('MMM DD, YYYY')
            });
            setTableData(sortedRows)

            dates.map((e, i) => {
                if( i >=1){
                    if(e > recentDate){ 
                        recentDate = e 
                    } 
                }else{
                    recentDate = e
                } 
            })
        
            const monthDonors = getLastMonthDonors(data, recentDate.slice(0,2))

            setSummary({
                totalDonations:amountFormat(+totalAmount.toFixed(2)),
                numberDonations:amountFormat(totalD),
                totalMonth:amountFormat(monthDonors)
            })
        }
    }

    function currencyFormat(num) {

        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    function amountFormat(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function getLastMonthDonors(data, month) {
        var total = []

        Array.prototype.contains = function(element) {
            return this.indexOf(element) > -1;
        };

        data.forEach(element => {
            if( !total.contains(element.email)){
                total.push(element.email)
            }
        });
        return total.length
    }

    async function fetchDonations(id) {
        const response = await OrganizationService.fetchDonations(id);
        if (response.success) {
            processData(response.data)
        }
        setCall2(true)
    }

    async function updateOrganization(id) {
        localStorage.setItem("stripeId", id)
        const data = { id: localStorage.getItem("organizationId"), bankInfo: id }
        await OrganizationService.updateOrg(data);
    }

    async function obtainAccounts(accountId) {
        const response = await StripeService.obtainAccounts(accountId);
        var found = false
        if(response.length > 0) { 
            if(response.email === localStorage.getItem("email")){
                found = true
                if(response.id) {
                    updateOrganization(response.id)
                } else {
                    setErrorStripe("error")
                    localStorage.setItem("noStripe", false)
                    if(access === "onboarding"){
                        setModal(true)
                    }
                }
            }

        }
        if(found == false){
            setNoStripe(true)
            localStorage.setItem("noStripe", true)
            if(access == "onboarding"){
                setModal(true)
            }
        }
    }

    async function fetchOrganization(id) {
        const response = await OrganizationService.fetchOrganization(id);
        if(response.data && response.success){
            if(response.data.logo){
                setLogo(response.data.logo)
                localStorage.setItem("organizationLogo", `${response.data.logo}`)
            }
            setOrgName(response.data.name);
        }
        localStorage.setItem("organizationId", `${response.data.id}`)
        setCall1(true)
    }

    async function fetchUserData() {
        const response = await UserService.fetchUserData(userId);
        if(access === "onboarding"){
            setModal(true)
        }

        if(response.error){
            history.push('/wizard')
        } else if(response?.success) {
            if(!localStorage.getItem("user-Name")){
                localStorage.setItem("user-Name", `${response.data.name}`) 
            }

            if (response.data) {
                setUserName(`${response.data.firstname} ${response.data.lastname}`)
                setUser(response.data);
            }
            
            if(response.data.stripe_customer_id){
                obtainAccounts(response.data.stripe_customer_id)
                localStorage.setItem("stripeId", response.data.stripe_customer_id)
            }
            
            if (response.data.organization) {
                localStorage.setItem("noStripe", false)
                localStorage.setItem("organizationId", `${response.data.organization.id}`)
                
                localStorage.setItem("organizationName", `${response.data.organization.name}`)
                setOrgName(response.data.organization.name)
                fetchOrganization(response.data.organization_id)
                fetchDonations(response.data.organization_id)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true)
        setId(localStorage.getItem("userId"))

        if (userId !== null && !user) {
            fetchUserData()
        }
    }, [userId])

    useEffect(() => {
        localStorage.setItem("errorStripe", errorStripe)
    }, [errorStripe])

    useEffect(() => {
        if(call1 && call2){
          setLoading(false)
        }
    }, [call1, call2]);

    useEffect(() => {
        if (!localStorage.getItem('email') || localStorage.getItem('email') === 'undefined') {
            history.push('/sign-in');
        }
    }, [])

    return (
        <div>
        {loading? 
            <Spinner></Spinner>
        :
            <Content
                classCss= "block"
            >
                <Content 
                    col 
                    classCss="dashboard-container"
                >
                    <Content
                        classCss="dashboard"
                    >
                        <Content
                        classCss="header"
                        >
                            <a href="/dashboard">
                                <img className="logo" src={bannerAsset} alt="logo" />
                            </a>
                        </Content>

                        <Spacer.Huge/>
                        
                        <Content
                        classCss="nameContainer"
                        >
                            <Content
                                classCss = "name-container dashboard-name"
                            >
                                <TextHg text={orgName}/> 
                            </Content>
                        </Content>

                        <Summary
                            currency = '$'
                            alt = {summaryData.totalDonations.split(".")[1]}
                            title='snapshot'
                            headers={headers}
                            content ={[
                                summaryData.totalDonations.split(".")[0],
                                summaryData.numberDonations,
                                summaryData.totalMonth
                            ]}
                        >
                        </Summary>

                        <Spacer.Huge/>
                        
                        <Table
                            title='history'
                            headers={tableHeaders}
                            content={tableData}
                        >
                        </Table>
                    </Content>
                    {(userName.length > 0 && (logo != null || logo != undefined))? 
                        <Menu
                            location ='dashboard'
                        >
                        </Menu>
                        :
                        null
                    }
                    {(access != null || access != undefined || access != "")?  
                        <Modal
                            show={openModal}
                            handleClose={() => setModal(false)}
                            stripe={!noStripe}
                        >
                        </Modal>
                        :null
                    }
                    
                    
                </Content>
            </Content>
        }
        </div>
    )
}

export default Dashboard
