import React from 'react'
import './App.css';
import 'font-awesome/css/font-awesome.min.css'
import './style.css' 
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import SignIn from 'views/Onboarding/SignIn'
import Wizard from 'views/Onboarding/wizard'
import Dashboard from 'views/Onboarding/dashboard'
import ProfileDashboard from 'views/Onboarding/OrganizationProfile'
import UserProfileSettings from 'views/Onboarding/UserProfileSettings'
import useAuth from 'hooks/useAuth';
import GuestGuard from 'components/GuestGuard';
import Landing from 'views/landing';
import Terms from 'views/terms';
import 'firebase';

function App() {

const auth = useAuth();

const checkUser = () => {
  if(localStorage.getItem('email') !== null ){
    return true
  }else {
    return false
  }
}

return (
    <BrowserRouter>
          <Switch>
              <Route 
                exact
                path="/organization-profile"
                name="Donorpal"
                component={ProfileDashboard}
              />
              <Route 
                exact
                path="/sign-in"
                name="Donorpal"
                component={SignIn}
              />
              <Route 
                  exact
                  path="/"
                  name="Donorpal"
                  component={Landing}
                />
              <Route
                exact 
                path="/wizard"
                name="Wizard"
                component={Wizard}
              />
              <Route
                exact 
                path="/dashboard/:access?"
                name="Dashboard"
                component={Dashboard}
              />
              <Route
                exact 
                path="/user-profile"
                name="user-profile"
                component={UserProfileSettings}
              />
              <Route
                exact 
                path="/terms"
                name="terms"
                component={Terms}
              />
          </Switch>
          
    </BrowserRouter>
    
  );
}

export default App;
