export default {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        minHeight: '100vh',
        
    },

    dashboard: {
        display: 'flex',
        flexDirection: 'column',
        flex: '75%',
        paddingTop: '80px',
        paddingLeft: '80px',
        paddingRight: '80px'
    },

    header: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'row',
        maxHeight: '24px'
    },

    nameContainer: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'column',
        maxHeight: '100px'
    },
    name: {
        display: 'flex',
        flex: '30%',
        maxWidth: '30%',
        flexDirection: 'row',
        maxHeight: '100px'
    },

    icon: {
        width: '24px',
        height: '24px',
    },

    logo: {
        width: '124px',
        height: '24px',
    },

    contentSaveButton: {
        width: '100%',
        top: '-80px',
        right: '-15px'
    },

    errorMsg:{
        justifyContent: 'center',
        fontFamily:' Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '30px',
        letterSpacing: '-0.03em',
        textAlign: 'center',
        color: 'red',
        width:'100%'
    },
    loginMsg:{
        justifyContent: 'center',
        fontFamily:' Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '30px',
        letterSpacing: '-0.03em',
        textAlign: 'center',
        width:'100%'
    },
    btn:{
        width: '200px' ,
       alignSelf: 'left'
    },
    stripeContainer:{
        justifyContent: 'center',
        flexDirection: 'column'
    }

}