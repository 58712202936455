export default [
    {
        type:'input',
        text:'What’s the name of your organization?',
        placeHolder: 'Legal organization name',
        textButton: 'continue',
        progress: '20%',
        name: 'organization'
    },
    {
        type:'input',
        text:'What should we call you?',
        placeHolder: 'Please enter your first and last name',
        textButton: 'continue',
        progress: '60%',
        name:'name'
    },
    {
        type:'input',
        text:'Awesome. All that’s left is to connect to Stripe, so you can get paid.',
        placeHolder: 'Select a state',
        textButton: 'Connect with Stripe',
        progress: '100%',
        name:'token'
    },

]