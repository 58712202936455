export const amplifyConfig = {
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: "us-east-1",
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
  };

  // {
  //   userPoolId?: string;
  //   userPoolWebClientId?: string;
  //   identityPoolId?: string;
  //   region?: string;
  //   mandatorySignIn?: boolean;
  //   cookieStorage?: ICookieStorageData;
  //   oauth?: OAuthOpts;
  //   refreshHandlers?: object;
  //   storage?: ICognitoStorage;
  //   authenticationFlowType?: string;
  //   identityPoolRegion?: string;
  //   clientMetadata?: any;
  // }
