import colors from '../../colors'

export default {
    textTransparent: {
        background: 'transparent',
        color: colors.primary,
        width: '320px',
        height: '62px', 
        justifyContent: 'center',
        textAlign: 'center',
        borderWidth: '0px',
        borderBottom:'1px solid #0C0030',
        borderBottom:'1px solid rgba(12, 0, 48, .3)',
        fontFamily: 'Circular Std',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
    },

    textForm: {
        background: 'transparent',
        color: colors.primary,
        width: '100%',
        justifyContent: 'center',
        textAlign: 'left',
        borderWidth: '0px',
        borderBottom:'1px solid #0C0030',
        borderBottom:'1px solid rgba(12, 0, 48, .3)',
        paddingBottom:'12px',
        paddingTop: '0px',
        height: '35px',
        fontFamily: 'Circular Std',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
    }
}