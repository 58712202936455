import colors from '../../../style/colors'

export default {
    text: {
        color: colors.primary,
        textAlign: 'center',
        fontFamily: 'Circular Std',
        fontWeight: 'normal',
        fontSize: '40px',
        lineHeight: '40px',
        letterSpacing: '-0.04em',
    }
}