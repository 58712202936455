import axios from "axios";

const apiBasePath = process.env.REACT_APP_DEV_API_URL_LOCAL

const WizardService = {
    fetchOrg: async function() { 
        const response = await axios.get(`${apiBasePath}/org`);
        return response.data;
    },
    fetchOrgByQuery: async function(query = '') { 
        const response = await axios.get(`${apiBasePath}/org?search=${query}`);
        return response.data;
    },
    getLinkTokenPlaid: async function(id){

        const response = await axios.post(`${apiBasePath}/user/obtain/plaid`, {userId : id})        
        return response.data;
    },

    create: async function(payload) {
        const response = await axios({
            method: 'PUT',
            headers: { "Content-Type": "application/json" },
            url: `${apiBasePath}/user/${payload.id}`,
            data: JSON.stringify(payload)   
        })
        return response.data;
    }
};


export default WizardService;
