export default {
    container: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'column',
        
    },

    title:{
        display: 'flex',
        flexDirection: 'column',
        flex: '100%',
        maxHeight: '72px',
    },

    titleText:{
        display: 'flex',
        flex: '100%',
        maxHeight: '32px',
        fontFamily:' Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '32px',
        letterSpacing: '-0.03em',
        textTransform: 'uppercase',
        textAlign: 'left',
        color: '#0C0030'
    },

    header: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'row',
        maxHeight: '40px',
        justifyContent: 'space-between',
        borderBottom: '2px solid #aca8d3',
    },

    headerText: {
        maxHeight: '40px',
        fontFamily:' Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
        textAlign: 'left',
        textTransform: 'capitalize',
        color: '#0C0030'
    },
    colLg: {
        display: 'flex',
        flex: '30%',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '30%',
    },
    colName: {
        display: 'flex',
        flex: '30%',
        flexDirection: 'row',
        maxWidth: '30%',
        paddingLeft:'8px'
    },
    colEmpty: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'column',
        maxWidth: '100%',
        justifyContent: 'center',
        textAlign: 'center',
        textTransform: 'inherit',
        paddingLeft:'8px'
    },
    colMd: {
        display: 'flex',
        flex: '20%',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '20%',
    },

    content: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'row',
        maxHeight: '80px',
        minHeight: '80px',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },

    contentText:{
        fontFamily: 'Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'left',
        color: '#0C0030',
        textTransform: 'capitalize'
        
    },

    userInitials:{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '24px',
        width: '48px',
        height: '48px',
        marginTop: '16px',
        background: '#0C0030',
        fontFamily: 'Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        justifyContent: 'center',
        color: '#EDEAFB',
        textTransform: 'uppercase'
    }

    
}