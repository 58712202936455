import React, { useState, useEffect } from 'react'
import { Media } from "reactstrap";
import { Redirect } from "react-router-dom";
import {
    Content, Spacer
} from 'components/index'
import './style.css'
import OrganizationService from 'services/organization_service'
import useAuth from 'hooks/useAuth';
import menuImg from 'assets/menu.png'
import AuthContext from 'contexts/AmplifyContext';
import { useHistory } from 'react-router-dom';


const Menu = ({ location = '' }) => {
    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [dashboard, goDash] = useState(false)
    const [userProf, goUProf] = useState(false)
    const [orgProf, goOProf] = useState(false)
    const [logoutUser, goOut] = useState(false)
    const {logout}= useAuth()
    const [menuClass, setMClass] = useState('menu')
    const [stripe, setStripe] = useState(() => {
        return localStorage.getItem("noStripe") || "";
    })
    const { user: userFromContext } = React.useContext(AuthContext);

    const [errorStripe, setErrorStripe] = useState(() => {
        return localStorage.getItem("errorStripe") || "";
    })

    const [user, setUser] = useState(() => {
        return localStorage.getItem("user-Name") || "";
    })

    const [logo, setlogo] = useState(() => {
        return localStorage.getItem("organizationLogo" || null);
    })

    const [orgID, setOrgID] = useState(() => {
        return localStorage.getItem("organizationId" || null);
    })

    const getInitials = (name) => {
        var initials = name.match(/\b(\w)/g);
        return initials.join('')
    }

    const logOut = () =>{
        logout()
        localStorage.clear()
        history.push('/sign-in');
    }

    const formatLogo = (logo) => {
        if (logo.indexOf("data:image") < 0 && logo.indexOf("http") < 0) {
            return `data:image/jpeg;base64,${logo}`;
          }
      
          return logo;
    }

    async function updateOrg(data) {
        const response = await OrganizationService.updateOrg(data);
        localStorage.setItem("organizationLogo", `${data.logo}`)
        setlogo(data.logo)
    }

    const clickInput = () => {
        document.getElementById('logo-org').click()
    }

    function toggleMenu(){
        if(menuClass === "menu"){
            setMClass('menu over')
        }else{
            setMClass('menu')
        }
    }

    function getWindowDimensions() {
        const { innerWidth: width } = window;
        return {
          width
        };
      }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                let data = {
                    logo: e.target.result.split(',')[1]
                }

                updateOrg({ ...data, id: orgID });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    useEffect(() => {
        setlogo(localStorage.getItem("organizationLogo"))
    }, [user, logo])

    useEffect(() => {
        setStripe(localStorage.getItem("noStripe"))
        setErrorStripe(localStorage.getItem("errorStripe"))
    },[stripe, errorStripe])

    useEffect(() => {
        function handleResize() {
            setWindowWidth(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        (windowWidth.width > 700 || (windowWidth.width < 700 && menuClass == "menu over"))?
            <Content
                row
                classCss={menuClass}
            >
                {
                    dashboard == true ? <Redirect exact from={`/${location}`} to="/dashboard" /> : null
                }
                {
                    userProf == true ? <Redirect exact from={`/${location}`} to="/user-profile" /> : null
                }
                {
                    orgProf == true ? <Redirect exact from={`/${location}`} to="/organization-profile" /> : null
                }
                {
                    logoutUser == true ? <Redirect exact from={`/${location}`} to="/" /> : null
                }

                {(windowWidth.width < 700 && menuClass == "menu over")?
                    <Content
                        classCss="mobile-menu-alt"
                    >
                        <img className="menu-icon" src={menuImg} onClick={() => { toggleMenu() }}></img>
                    </Content>
                :

                    <Content
                        classCss="initialsContainer"
                        
                    >
                        <div
                            className="initials"
                            onClick={() => { toggleMenu() }}
                        >
                            {user ? getInitials(user) : null}
                        </div>
                    </Content>
                }
                <Spacer.Huge />
                <Content
                    classCss="logoContainer"
                >
                    {(logo != null && logo != '')?
                        <div className="menu-logo">
                            <Media
                                object
                                data-src={formatLogo(logo)}
                                src={formatLogo(logo)}
                                alt="Generic placeholder image"
                                style={{ maxWidth: "120px" }}
                            />
                            <input type="file" onChange={onImageChange} className="filetype" id="logo-org" style={{ display: 'none' }} />
                        </div>
                        : <input type="file" onChange={onImageChange} className="filetype" id="logo-org" style={{ display: 'none' }} />
                    }
                    
                    {location === 'organization-profile' ?
                        (logo != null && logo != '') ?
                            <a
                                className="changeLink"
                                onClick={clickInput}
                            >
                                (Change Logo)
                            </a>
                            :
                            <a
                                className="changeLink"
                                onClick={clickInput}
                            >
                                (Upload Logo)
                            </a>
                        : null}
                </Content>
                <Spacer.Large />

                <Content
                    classCss="sectionContainer menuTitle"
                >
                    <span className="sectionTitle">
                        quick links
                    </span>
                </Content>
                <Spacer.Medium />
                {location === 'dashboard' ?
                    null
                    :
                    <Content
                        classCss="sectionContainer"
                    >
                        <span className="sectionLink" onClick={() => { goDash(true) }}>
                            Dashboard
                        </span>
                    </Content>
                }
                {location === 'dashboard' ?
                    null
                    :
                    <Spacer.Medium />
                }

                {location === 'userProfile' ?
                    null
                    :
                    <Content
                        classCss="sectionContainer"
                    >
                        <span className="sectionLink" onClick={() => { goUProf(true) }}>
                            User profile settings
                        </span>
                    </Content>
                }
                {location === 'userProfile' ?
                    null
                    :
                    <Spacer.Medium />
                }
                {location === 'organization-profile' ?
                    null
                    :
                    <Content
                        classCss="sectionContainer"
                    >
                        <span className="sectionLink" onClick={() => { goOProf(true) }}>
                            Organization profile
                        </span>
                    </Content>
                }
                <Spacer.Medium />
                <Content
                    classCss="sectionContainer"
                >
                    <span className="sectionLink" onClick={() => { logOut() }}>
                        Log out
                    </span>
                </Content>
                
                <Spacer.Medium />
            
                {   

                    (location !== 'organization-profile' &&
                        !userFromContext?.stripe_customer_id &&
                        (!localStorage.getItem('stripeId') || localStorage.getItem('stripeId') === 'undefined')) ?
                    
                        <Content
                            classCss="errorContainer"
                        >
                            <span className="sectionError" onClick={() => { goOProf(true) }}>
                                Please connect your Stripe account
                            </span>
                        </Content>
                    :
                    null
                }
            </Content>
        :
            <Content
                classCss="mobile-menu"
             >
                 <img className="menu-icon" src={menuImg} onClick={() => { toggleMenu() }}></img>
            </Content>
        
    )
}

export default Menu
