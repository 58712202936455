import React, { useState } from 'react';
import './style.css'

const Accordion = ({ index, title, content, img }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title-container" onClick={() => setIsActive(!isActive)}>
        <div className="accordion-index">{index < 10 && 0}{index}</div>
        <div className="accordion-title">{title}</div>
        <div className="accordion-icon">{isActive ? '-' : '+'}</div>
      </div>
      <div className={`accordion-content ${isActive ? 'active' : 'accordion-content'}`}>
        <span>{isActive ? content : null}</span>
      </div>
    </div>
  );
};

export default Accordion;
