export default [
    {
      title: 'search over 1 million nonprofits',
      content: `Help make the world a better place, 
      from anywhere! Search from over 1.2 million charities all through your phone! 
      DonorPal helps you make donations to your favorite charities from one place. 
      Never go through the hassle of searching each individual charity to do good again.  
      Instead, successfully give with a single search and tap.`
    },
    {
      title: 'make one time or recurring donations',
      content: `In today’s instantaneous digital age, use DonorPal as your digital donation journal 
      that keeps track of all the charities you’ve ever donated to. Revisit them again and again. 
      Donate once, twice, or as many times as your generosity demands it.  
      Just open up our app, tap, and donate.`
    },
    {
      title: 'receive instant tax recepits',
      content: `Want the proper credit for doing a good deed? DonorPal instantly 
      organizes and sends all your digital tax receipts directly to your email! 
      Keep track of exactly how much you gave through your donations history page. 
      Never dig through a pile of paper receipts again. 
      Eliminate that paper trail and be earth friendly at the same time.`
    },
    {
      title: '100% free, forever',
      content: `Giving should be easy and free!  No extra costs and no hidden fees. 
      DonorPal truly believes in the spirit of generosity, and you shouldn’t have to pay extra just to give a little extra. 
      \n Start your cost-free journey with your charity companion, DonorPal. Download and begin making a positive difference.
      `
    }
  ];