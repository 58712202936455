import colors from '../../../style/colors'

export default {
    text: {
        color: colors.primary,
        textAlign: 'center',
        fontFamily: 'Circular Std',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '19px',
        letterSpacing: '-0.02em',
        fontFamily: 'inherit',
        opacity: 0.5
    }
}