import React, { useState, useEffect } from 'react'
import styles from './style.js'
import './style.css'
import Pagination from 'components/elements/paginator/index'; 
import {
    Content, Spacer
} from 'components/index'

let PageSize = 10;

const Table = ({ title, headers, content}) => {
    const [currentPage, setCurrentPage] = useState(1);

    function currentTableData() {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        
        return content.slice(firstPageIndex, lastPageIndex)
    }

    
    const getInitials = (name)=> {
        var initials = name.match(/\b(\w)/g);
        return <span className="table-userInitials">{initials.join('')}</span>     
    }

    return (

        
        <Content
            classCss="table-container"
        >
            <Content
                classCss="table-title"
            >
                <span className="table-titleText">
                    {title}
                </span>
                <Content
                    classCss="table-header"
                >
                    {headers.map((e) => {
                        return <span style={{
                            ...styles[e.size]
                        }}
                        className="table-headerText">{e.name}</span>
                    })}
                </Content>
            </Content>

            <Spacer.Medium/>

           
                {currentTableData().map((data) =>
                    
                    <div
                        className="table-row table-content"
                    >   

                        {headers.map((e) =>
                            e.name === 'name'? 
                                <span className="table-contentText colName">
                                    {getInitials(data[e.name])}
                                    <a style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginLeft: '12px'
                                    }}
                                    className="table-contentText">{data[e.name]} </a>
                                </span>
                            :
                                <span style={{
                                    ...styles[e.size]
                                }}
                                className="table-contentText">
                                    {data[e.name]}
                                </span>
                        )}
                    </div>
                        
                )}

                {content.length>0?
                    null
                    :
                    <span className="table-contentText colEmpty" >
                        There are no donations yet.
                    </span>
                }
            
            <Spacer.Medium/> 

            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={content.length}
                pageSize={PageSize}
                onPageChange={page => {setCurrentPage(page); currentTableData()}}
            />
              
            
        </Content>
    )
}

export default Table
