import axios from "axios";

const apiBasePath = process.env.REACT_APP_DEV_API_URL_LOCAL

const StripeService = {
    obtainLink: async function(email) { 
        const response = await axios.post(`${apiBasePath}/user/obtain/stripe`,{ email:email });
        return response.data;
    },
    obtainAccounts: async function(email) { 
        const response = await axios.post(`${apiBasePath}/user/obtain/stripe/accounts`, {email:email});
        return response.data;
    },
    deleteAccount: async function(account) { 
        const response = await axios.post(`${apiBasePath}/user/delete/stripe/account`,{account:account});
        return response.data;
    },
    
    loginLink: async function(account) { 
        const response = await axios.post(`${apiBasePath}/user/obtain/stripe/login`,{account:account});
        return response.data;
    },
};

export default StripeService;
