import * as React from 'react';
import {
    Content, Spacer
} from 'components/index'
import './style.css';
import bannerAsset from 'assets/Logo.svg'
import { useHistory } from 'react-router-dom';
import TextHg from 'components/texts/Text-hg';
import definitions from "./definitions";
import {
  additional_rules,
  applicability_arbitration,
  app_updates_upgrades,
  authority_arbitration,
  basics_givers,
  basics_nonprofits,
  claims,
  code_conduct,
  confidentiality,
  digital_copyright,
  disclaimer_limitations,
  dispute_resolution,
  emergency_equitable,
  indemnification,
  miscellaneous,
  notice_requirement,
  preterms,
  property_rights,
  termination_suspension,
  time_limits,
  user_content,
  waiver_class,
  waiver_jury,
} from "./copy";


const Terms = () => {
    const history = useHistory();

    const renderTerms = React.useCallback(
        (terms) =>
          terms.map((term) => (
            <>
              <Content>
                <p>{term.text}</p>
              </Content>
              <Spacer.Medium />
            </>
          )),
        []
      );
    
      const Definitions = React.useMemo(
        () =>
          definitions.map((definition) => (
            <>
              <Content>
                <h5>{definition.title}</h5>
                <p>{definition.text}</p>
                </Content>
              <Spacer.Medium />
            </>
          )),
        []
      );

    return <Content classCss="terms">
        <Content classCss="header">
              <img src={bannerAsset} onClick={() => history.push('/')} className="logo" alt="logo" />
              <span className="loginText" onClick= {()=> history.push('/sign-in')}>Organization sign in/up</span>
            </Content>
            <Spacer.Huge />
            <TextHg text="Terms of Service" />

            <Spacer.Large />
            {renderTerms(preterms)}

            {Definitions}

            <h5>
              {"The Basics for Givers".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(basics_givers)}

            <h5>
              {"The Basics for Nonprofits".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(basics_nonprofits)}

            <h5>
              {"Intellectual Property Rights Generally".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(property_rights)}

            <h5>
              {"User-Generated Content (“UGC”)".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(user_content)}

            <h5>
              {"Digital Millennium Copyright Act".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(digital_copyright)}

            <h5>{"Code of Conduct".toUpperCase()}</h5>
            <Spacer.Medium />

            {renderTerms(code_conduct)}

            <h5>
              {"App Updates and Upgrades".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(app_updates_upgrades)}

            <h5>
              {"Disclaimer and Limitation of Liability".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(disclaimer_limitations)}

            <h5>{"Indemnification".toUpperCase()}</h5>
            <Spacer.Medium />

            {renderTerms(indemnification)}

            <h5>
              {"Dispute Resolution".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(dispute_resolution)}

            <h5>
              {"Applicability of Arbitration Agreement.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(applicability_arbitration)}

            <h5>
              {"Notice Requirement and Informal Dispute Resolution.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(notice_requirement)}

            <h5>
              {"Additional Rules for Non-Appearance Based Arbitration.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(additional_rules)}

            <h5>{"Time Limits.".toUpperCase()}</h5>
            <Spacer.Medium />

            {renderTerms(time_limits)}

            <h5>
              {"Authority of Arbitrator.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(authority_arbitration)}

            <h5>
              {"Waiver of Jury Trial.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(waiver_jury)}

            <h5>
              {"Waiver of Class or Consolidated Actions.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(waiver_class)}

            <h5>{"Confidentiality.".toUpperCase()}</h5>
            <Spacer.Medium />

            {renderTerms(confidentiality)}

            <h5>
              {"Emergency Equitable Relief.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(emergency_equitable)}

            <h5>
              {"Claims Not Subject to Arbitration.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(claims)}

            <h5>
              {"Claims Termination and Suspension.".toUpperCase()}
            </h5>
            <Spacer.Medium />

            {renderTerms(termination_suspension)}

            <h5>{"Miscellaneous.".toUpperCase()}</h5>
            <Spacer.Medium />

            {renderTerms(miscellaneous)}
            <Spacer.Huge />
    </Content>
}

export default Terms;
