import React from 'react'

const Spacer = ({ padding }) => {
    return (
        <div style={{
            padding,
        }} />
    )
}

export default Spacer