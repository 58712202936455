import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// import config from 'aws-exports'
// import Amplify, { Auth } from 'aws-amplify';
// Amplify.configure(config)

import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from 'config';
import UserService from 'services/user_service';
Amplify.configure(amplifyConfig);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  id: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  ID_USER: (state, action) => {
    const { id_user } = action.payload;

    return {
      ...state,
      id:id_user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  id_user: () => {},
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      let user;
      const userFromStorage = localStorage.getItem('userId');

      
      try {
        if (userFromStorage) {
          user = await UserService.fetchUserData(userFromStorage);
        }

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: user.data,
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    try{
      const user = await Auth.signIn(email, password);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.attributes.sub,
            email: user.attributes.email,
          }
        }
      });

      return {status: true , data: user}
  }catch(err){
      return {status: false , data: err.message}
  }
    
  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };
  const id_user = async (idUser) => {
    dispatch({
      type: 'ID_USER',
      payload: {
        id_user:idUser        
      }
    });
  };

  const register = async (email, password) => {
    try{
      const res = await Auth.signUp({
        username: email,
        password,
        attributes: { email }
      });
      dispatch({
        type: 'REGISTER'
      });   
      return {status:true , data: res}

  }catch(err){
      return {status:false , data: err.message}
  }

  };

  const verifyCode = async (username, code) => {
    try{
      const res = await Auth.confirmSignUp(username, code);
      dispatch({
        type: 'VERIFY_CODE'
      });  
      return {status:true , data: res}

  }catch(err){
      return {status:false , data: err.message}
  }
    
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    try{
      const res = await Auth.forgotPasswordSubmit(username, code, newPassword);
      dispatch({
        type: 'PASSWORD_RESET'
      });  
      return {status:true , data: res}

    }catch(err){
        return {status:false , data: err.message}
    }       
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        id_user,
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
