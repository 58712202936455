import colors from '../../../style/colors'

export default {
    link: {
        backgroundColor: 'transparent',
        color: colors.primary,
        opacity: 0.5,
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '24px',
        letterSpacing: '-0.02em',
        cursor: 'pointer',
        fontFamily: 'inherit',
        borderBottom: "1px solid rgba(12, 0, 48, .5)"    
    }
}