import React, { useState } from 'react'
import { Button } from 'reactstrap'
import styles from './style'

const TertiaryButton = ({ children, text, onClick, imgUrl, styleBtn = null, classCss }) => {
    const [touched, setTouched] = useState(false)

    const handleMouseUp = () => {
        setTimeout(() => {
            setTouched(false)
        }, 150)
    }

    const handleMouseDown = () => {
        setTouched(true)
    }

    return (

        <Button 
            className={classCss}
            style={{...styles.button, ...styleBtn}} 
            onClick={onClick}
        >   
            <img style={styles.img} src={imgUrl} />
            <div style={styles.div}>
                <span style={styles.small}>{text.small}</span>
                <span style={styles.big}>{text.big}</span>
            </div>
            
        </Button>
    )
}

export default TertiaryButton