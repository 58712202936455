import React from 'react'
import './style.css'


const Footer = () => {
    return (
   
        <footer className="footer-container">
            <div className="footer-content">
                <span>DonorPal©️ 2021</span>
            </div>
        </footer>
    );
}

export default Footer;