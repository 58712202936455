import React, { useState } from 'react'
import styles from './style'
import './style.css'
import {
    Content, Spacer
} from 'components/index'

const Summary = ({ title, headers, content, currency, alt }) => {
    return (

        
        <Content
            classCss="summary-container"
        >
            <Content
                classCss="summary-title"
            >
                <span className="titleText">
                    {title}
                </span>
            </Content>
            <Spacer.Medium/>
            <Content
                classCss="summary-header"
            >
                {headers.map((e) => {
                    return <span className="headerText">{e.name}</span>
                })}
            </Content>
            <Content
                classCss="summary-content"
            >
                {currency? 
                    <div style={{flexDirection:'row'}} className="contentText">
                        <span className="contentAltSmall">{currency}</span>
                        <span className="contentAlt">{content[0]}</span>
                        <span className="contentAltSmall">{alt}</span>
                    </div>
                    :
                    null
                }
                {content.map((e,i) => {
                    return (currency && i === 0)?
                        null
                        :
                        <span className="contentText" name={e}>{e}</span>
                })}
                

            </Content>
        </Content>
    )
}

export default Summary