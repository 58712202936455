export default {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignConten: 'center',
        textAlign: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '12px'
    },

    noGrow:{
        flexGrow: 'unset',
    }
}