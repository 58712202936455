import React, { useState, useEffect } from 'react'
import {
    Content, Spacer
} from 'components/index'
import {FormFeedback} from 'reactstrap'
import ReactDropDownAutoComplete from "react-dropdown-autocomplete"
import { Redirect } from "react-router-dom";
import WizardService from 'services/wizard_service'
import StripeService from 'services/stripe_service'
import PrimaryButton from 'components/buttons/primaryButton/index'
import PrimaryLink from 'components/links/primaryLink/index'
import styles from './style'
import questions from './questions'
import inputs from 'style/inputs/text/index'
import bannerAsset from 'assets/Logo.svg';
import iconX from 'assets/Union.svg';
import ProgressBar from 'components/ProgressBar'
import TextLg from 'components/texts/Text-lg'
import 'style/inputs/autocomplete/index.css'
import './style.css'
import { useQuery } from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';


const Wizard = () => {
    // Estado que permite cambiar las preguntas
    const disclaimer = "If your organization name don't appear in the list, please send an email to "
    const history = useHistory();
    const [question, setQuestion] = useState(0);
    const [currentSelected, setCurrentSelected] = useState();
    const [backToHome, goBack] = useState(false)
    const [dashboard, goDash] = useState(false)
    const [, setOrg] = useState([])
    const [filterOrgs, setFilter] = useState([])
    const [field, setField] = useState('');
    const [org, hasOrg] = useState(false)
    const [hasStripe, setStripe] = useState(false)
    const [dataInput, setDataInput] = useState({
        id:'',
        organization_id:'',
        name:'',
        token_plaid:null
    })
    const [userId, setId] = useState(null)
    const [errorOrg, setErrorOrg] = useState(false)
    const query = useQuery()
    const user_id = query.userId


    // Funcion que cambia la pregunta a hacer click
    const handleButton = ()=> {
        let error = false
        let found = false
        if(question === 0){
            setErrorOrg(false)
            filterOrgs.map((doc) => {
                if (found === false){
                    console.log(doc.name)
                    console.log(field)
                    if (doc.name === field){
                        doc.bankInfo !== undefined && setStripe(true)
                        setDataInput({
                            ...dataInput,
                            organization_id:doc.id,
                            id:user_id
                        })
                        localStorage.setItem("userId", user_id);
                        localStorage.setItem("organizationId", doc.id);
                        error = false
                        found = true
                        setErrorOrg(false)
                    } else {
                        error = true
                        setErrorOrg(true)
                    } 
                }
            })           
        }

        if(question<2 && !error && !!filterOrgs.length){
            let nextQuestion =  question+1
            setQuestion(nextQuestion)
        } else {
            setErrorOrg(true)
        }

        if(question === 2){

            obtainLink()
        }     
    }

    const previous = ()=> {
        if(question>=1){
            let previous =  question-1
            setQuestion(previous)
        }        
    }

    async function changeOrgName(value) {
        if (value.length > 2) {
            fetchOrgByQuery(value);
        } else if (value.length <= 2) {
            setFilter([]);
            closeList();
        }
    }

    const backHome = ()=> {
        goBack(true)      
    }

    async function fetchOrgByQuery(value) {
        const response = await WizardService.fetchOrgByQuery(value);
        setFilter(response.data);

        if (response.data?.find((org) => org.name.toLowerCase() === value.toLowerCase())) return;

        if (response.data?.length) {
            openList();
        } else {
            closeList();
        }
    }

    function openList() {
        document.getElementsByClassName('autocomplete-list')[0]?.classList.add('show');
    }

    function closeList() {
        document.getElementsByClassName('autocomplete-list')[0]?.classList.remove('show');
    }
    
    async function fetchOrg() {
        const response = await WizardService.fetchOrg();
        return (response.data.length === 0) ? alert("there are no organizations") : setOrg(response.data)
    }

    async function createOrgSimple() {
        await WizardService.create(dataInput);
        goDash(true)
    }

    async function obtainLink() {
        const response = await StripeService.obtainLink(localStorage.getItem("email"));
        createOrgSimple()

        if (response.data && response.data.url) {
            window.location.href = response.data.url;
        }
    }

    const changeInput = (e) => {
        setDataInput({
            ...dataInput,
            [e.target.name]: e.target.value
        })
    }

    const debounceSearch = React.useMemo(
        () => debounce(changeOrgName, 300),
        []
    );

    const handleClickOutside = (e) => {
        if (!document.getElementsByClassName('autocomplete-field')[0]?.contains(e.target)){
            closeList();
        }
    }

    useEffect(() => {
        
        if (user_id) {
            setId(user_id)
            fetchOrg()
        }else{
            setId(localStorage.getItem("userId"))
        }
        setDataInput({
            ...dataInput,
            id:user_id
        })
    }, [userId, dashboard])

    useEffect(() => {
        if (!localStorage.getItem('email') || localStorage.getItem('email') === 'undefined') {
            history.push('/sign-in');
        }

        window.addEventListener('click', handleClickOutside);

        return () => window.removeEventListener('click', handleClickOutside);
    }, [])

    return (
        <Content classCss="wizard-container">
            {
                backToHome ? <Redirect exact from="/wizard" to="/" /> : null
            }
            {
                dashboard ? <Redirect exact from="/wizard" to="/dashboard" /> : null
            }
            <Content
                classCss="header"
            >   
                <div style = {{width: '20%'}}>
                    <a href="/dashboard">
                        <img className="logo" src={bannerAsset} alt="logo" />
                    </a>
                </div>
                
                <div className="progressBar">
                    <ProgressBar height={'4px'} width={'320px'} color='#001AFF' progress={questions[question].progress}/>
                </div>

                <div style = {{width: '20%' , display: 'flex', justifyContent: 'flex-end', cursor:'pointer'}}>
                    <img src={iconX} style={styles.icon} onClick={() => backHome()} alt="back icon" />
                </div>
            </Content>
            <Content
                col
                justifyCenter
                alignCenter
                style={{marginTop:'50px'}}
            >
                {(question === 2 && hasStripe)?
                    <TextLg text="Congratulations, your organization allready has a Stripe account connected with Donorpal."/>
                    :
                    <TextLg text={questions[question].text}/>
                }
                

                <Spacer.Large/>
                {question === 0?
                    <ReactDropDownAutoComplete
                        id="autocomplete-select"
                        getItemValue={item => item.name}
                        className="auto-complete"
                        placeholder={questions[question].placeHolder}
                        data={filterOrgs}
                        renderItem={item => (
                            <div>
                                {item.name}
                                <span style={{textAlign: "right"}}>
                                    {item.ein.slice(0,2)}-{item.ein.slice(3)}
                                </span>
                            </div>
                        )}
                        value={field}
                        onChange={(val) => {
                            closeList();
                            setField(val);
                            hasOrg(true);
                            debounceSearch(val);
                        }}
                    />
                    :
                    question<2?
                        <input 
                            type='text'
                            placeholder={questions[question].placeHolder}
                            name={questions[question].name}
                            onChange={(e)=>{changeInput(e)}}
                            autoComplete="off"
                            value={dataInput[questions[question].name]}
                            style={{ 
                                ...inputs.textTransparent,
                                outline: 'none !important',
                            }}

                        />
                        : null
                }
                
                <Spacer.Large/>
                {question === 0 &&
                    <PrimaryButton  
                        text={questions[question].textButton}
                        onClick={handleButton}
                        disabled={!org}                     
                    />
                }
                {errorOrg && 
                    <FormFeedback style={{display: 'flex', justifyContent: 'center'}} invalid>please, select a valid organization from list</FormFeedback>
                }
                {question === 0 && 
                    <span className="wizard-message" style={{paddingTop:'30px'}}>{disclaimer}<a href="mailto:hello@donorpal.com">hello@donorpal.com</a></span>
                }
                {question === 1 &&
                    <PrimaryButton  
                        text={questions[question].textButton}
                        onClick={handleButton}
                        disabled={(dataInput[questions[question].name] === '' && question !== 2) ? true: false}                     
                    />
                }
                {question === 2 ?
                    !hasStripe ? 
                        <PrimaryButton  
                            text={questions[question].textButton}
                            onClick={handleButton}
                            disabled={(dataInput[questions[question].name] === '' && question !== 2) ? true: false}                     
                        />
                        :
                        <PrimaryButton  
                            text="Finish register"
                            onClick={createOrgSimple}                     
                        />
                    :
                    null
                }
                <Spacer.Medium/>
                {question>=1?
                    <PrimaryLink 
                        text="Previous question"
                        onClick={previous}

                    >
                    </PrimaryLink>
                    : null
                }
                <Spacer.Medium/>
                {(question === 2 && !hasStripe)?
                    <PrimaryLink 
                        text="Skip step"
                        onClick={createOrgSimple}
                    >
                    </PrimaryLink>
                    : null
                }
            </Content>
        </Content>
    )
}

export default Wizard;
