import React, { useState, useEffect } from "react"
import { Content, Spacer } from "components/index"
import styles from "./style"
import bannerAsset from "assets/Logo.svg"
import TextHg from "components/texts/Text-hg"
import Menu from 'views/menu/index'
import Form1 from "./Form"
import { useHistory } from "react-router-dom"

const UserProfileSettings = () => {
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('email') || localStorage.getItem('email') === 'undefined') {
        history.push('/sign-in');
    }
  }, [])

  return (
    <Content col style={styles.container}>
      <Content style={styles.dashboard}>
        <Content style={styles.header}>
          <a href="/dashboard">
              <img className="logo" src={bannerAsset} alt="logo" />
          </a>
        </Content>
        <Spacer.Huge />
        <Content style={styles.nameContainer}>
          <Content style={styles.name}>
            <TextHg text="USER PROFILE SETTINGS" />
          </Content>
          <Form1 />
        </Content>
        <Spacer.Large />
      </Content>
      <Menu location="userProfile"></Menu>
    </Content>
  );
};

export default UserProfileSettings; 
