import React, { useState } from 'react'
import styles from './style'

const SecondaryText = ({ text }) => {
    
    return (
        <span
            style={{ 
                ...styles.text,
            }}
        >
            {text}
        </span>
    )
}

export default SecondaryText