import React, { useState } from "react"
import { Content, Spacer } from "components/index"
import styles from "./style"
import './style.css'
import bannerAsset from "assets/Logo.svg"
import TextHg from "components/texts/Text-hg"
import Menu from 'views/menu/index'
import Form1 from "./Form"

const profile = () => {
  return (
    <Content col classCss="org-container">
      <Content classCss="org-dashboard">
        <Content classCss="org-header">
          <a href="/dashboard">
              <img className="logo" src={bannerAsset} alt="logo" />
          </a>
        </Content>
        <Spacer.Huge />
        <Content classCss="nameContainer">
          <Content classCss="name">
            <TextHg text="ORGANIZATION PROFILE" />
          </Content>
          <Form1 />
        </Content>
        <Spacer.Large />
      </Content>
      <Menu location="organization-profile"></Menu>
    </Content>
  );
};

export default profile;
