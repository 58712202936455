import React, { useState } from 'react'
import styles from './style'

const TextHg = ({ text, classCss }) => {
    
    return (
        <h1
            style={{ 
                ...styles.text,
            }}
            className={classCss}
        >
            {text}
        </h1>
    )
}

export default TextHg;