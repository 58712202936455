import React from 'react'
import { Button } from 'reactstrap'
import styles from './style'

const SecondaryButton = ({ text, onClick, containerStyle, imgUrl }) => (
    <Button 
        style={{...styles.button, ...(containerStyle || {})}} 
        onClick={onClick}
    >   
        <img style={styles.img} src={imgUrl} />
        {text}
    </Button>
)

export default SecondaryButton