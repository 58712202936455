export default {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        minHeight: '100vh',

    },

    dashboard:{
        display: 'flex',
        flexDirection: 'column',
        flex: '75%',
        paddingTop: '80px',
        paddingLeft: '80px',
        paddingRight: '80px'
    },

    header: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'row',
        maxHeight: '24px'
    },

    nameContainer: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'column',
        paddingTop:'20px',
        maxHeight: '100px'
    },
    name: {
        display: 'flex',
        flex: '60%',
        maxWidth: '60%',
        flexDirection: 'row',
        maxHeight: '100px'
    },

    icon:{
        width: '24px',
        height: '24px',
    },

    logo:{
        width: '124px',
        height: '24px',
    },
    contentSaveButton: {
        width: '100%',
        top: '-95px',
        right: '-15px'
    },
    label:{
        fontFamily: 'Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
        color: '#0C0030',
        opacity: 0.5,
    }
}