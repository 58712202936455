import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import inputs from "style/inputs/text/index";
import select from "style/inputs/select/index";
import styles from "./style";
import PrimaryButton from "components/buttons/primaryButton/index";
import StripeService from "services/stripe_service"
import AuthService from "services/auth_service";
import OrganizationService from "services/organization_service";
import UserService from 'services/user_service'
import states from "statesandcities/states";
import cities from "statesandcities/cities";
import Spinner from "components/spinner/index"
import { useQuery } from 'hooks/useQuery';

const Form1 = () => {
  const [isSave, setIsSave] = useState(false);
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [tax, setTax] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [errorMailingAddress, setErrorMailingAddress] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorZipcode, setErrorZipcode] = useState(false);
  const [errorEin, setErrorEin] = useState(false);
  const [, setStripe] = useState(null)
  const [, showCompleteStripe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    name: "",
    mailingAddress: "",
    city: "",
    state: "",
    zipcode: "",
    ein: ""
  });
  const [call1, setCall1] = useState(false);
  const [call2, setCall2] = useState(false);
  const query = useQuery();
  const stripe_callback = query.s
  const stripe_account_id =query.ue
  const [user, setUser] = useState({id: '', stripe_customer_id: '', email:''})


  function validateEin(ein) {
    if (ein !== "") {
      setErrorEin(false);
      return true;
    } else {
      setErrorEin(true);
      return false;
    }
  }
  function validateZipcode(zipcode) {
    if (zipcode !== "") {
      setErrorZipcode(false);
      return true;
    } else {
      setErrorZipcode(true);
      return false;
    }
  }
  function validateState(state) {
    if (state !== "") {
      setErrorState(false);
      return true;
    } else {
      setErrorState(true);
      return false;
    }
  }
  function validateCity(city) {
    if (city !== "") {
      setErrorCity(false);
      return true;
    } else {
      setErrorCity(true);
      return false;
    }
  }
  function validateName(name) {
    if (name !== "") {
      setErrorName(false);
      return true;
    } else {
      setErrorName(true);
      return false;
    }
  }
  function validateMailingAddress(mailingAddress) {
    if (mailingAddress !== "") {
      setErrorMailingAddress(false);
      return true;
    } else {
      setErrorMailingAddress(true);
      return false;
    }
  }
  function setCharAt(str,index,chr) {
    if(index >= str.length-1) {
      return str;
    }
    return str.substring(0,index+1) + chr + str.substring(index+1);
  }

  function taxtMask(data) {
    setTax(setCharAt(data.ein,1,'-'))
  }

  async function updateOrg() {
    await OrganizationService.updateOrg(data);
    setIsSave(false);
  }

  async function obtainLink() {
    const response = await StripeService.obtainLink(user.email);
    if (response.data.url) {
      window.location.href = response.data.url;
    }
  }

  async function getUserById() {
    const id = localStorage.getItem("userId")
    const orgId = localStorage.getItem("organizationId");
    const user = await AuthService.getUserById(id);
    setUser({
      ...user.data,
      id: id,
      email: user.data.email
    })
    const response = await OrganizationService.fetchOrganization(orgId);

    if (response.data) {
      taxtMask(response.data)
      setData(response.data);
      setCall1(true)
      getListStates(response.data.state);
    }
  }

  async function obtainAccounts() {
    let accountResult;
    const id = localStorage.getItem("userId")
    const orgId = localStorage.getItem("organizationId");

    if(!user?.stripe_customer_id && stripe_callback){
      localStorage.setItem("noStripe", false)
      accountResult = await StripeService.obtainAccounts(stripe_account_id);
      setUser({
        ...user,
        stripe_customer_id: stripe_account_id
      })
      await UserService.updateUser({ id, stripe_customer_id: stripe_account_id, organization_id: orgId, sendEmail: true });
    }else {
      accountResult = await StripeService.obtainAccounts(user.stripe_customer_id);
    }
    

    if (accountResult.data && accountResult.success) {
      if (accountResult.data.id === stripe_account_id) {
          showCompleteStripe(true)
      }
    }
    setCall2(true)
  }

  const handleInputChange = (event) => {
    if (event.target.name === "taxId") {
      let tax = event.target.value.replace(/\-/gi, "");
      data.ein = tax
      taxtMask(data)
    }
    if (event.target.name === "state") {
      getStateCities(event.target.value);
    }
    setIsSave(true);
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const save = (event) => {
    event.preventDefault();
    const responseName=validateName(data.name);
    const  responseMailingAddress = validateMailingAddress(data.mailingAddress)
    const  responseEin = validateEin(data.eing)
    const  responseZipcode = validateZipcode(data.zipcode)
    const  responseState = validateState(data.state)
    const  responseCity = validateCity(data.city)
    if (responseName && responseMailingAddress && responseEin && responseZipcode && responseState && responseCity) {
      updateOrg(data);
    }
  };

  const getListCities = (citiesOrg) => {
    let listCities = citiesOrg.map((item) =>
      data.city === item.name ? (
        <option selected>{item.name}</option>
      ) : (
        <option>{item.name}</option>
      )
    );
    setListCities(listCities);
  };

  const getListStates = (state) => {
    let listStates = states.map((item) =>
      state === item.Code ? (
        <option value={item.Code} selected>
          {item.State}
        </option>
      ) : (
        <option value={item.Code}>{item.State}</option>
      )
    );
    setListStates(listStates);
    getStateCities(state);
  };

  const getStateCities = (stateOrg) => {
    let state = states.filter((e) => e.Code === stateOrg);
    if (state.length > 0) {
      let citiesOrg = cities.filter((c) => c.usps === state[0].Code);
      getListCities(citiesOrg);
    }
  };

  useEffect(() => {
    setLoading(true)
    setStripe(localStorage.getItem("stripeId"))
    getUserById();
  }, []);

  useEffect(() => {
    if ((user && user.stripe_customer_id) || stripe_callback) {
      obtainAccounts();
    }else {
      setCall2(true);
    }
  }, [stripe_callback])
  
  useEffect(() => {
    if(call1 === true && call2 === true){
      setLoading(false)
    }
  }, [call1, call2]);

  return (
    <div>
      {loading? 
        <Spinner></Spinner>
    :
   
    <Form
      className="mt-4 position-relative needs-validation"
      style={{ width: "100%" }}
    >
      <FormGroup
        row
        className="position-absolute"
        style={styles.contentSaveButton}
      >
        {isSave ? (
          <Col className="d-flex justify-content-end">

            <PrimaryButton
              styleBtn={{maxWidth: '58px'}}
              text="Save"
              onClick={(event) => {
                save(event);
              }}
            />
          </Col>
        ) : null}
      </FormGroup>
      <FormGroup row>
        <Label for="organization" sm={12} className=" label">
          Organization name
        </Label>
        <Col sm={12}>
          <input
            type="text"
            style={{
              ...inputs.textForm,
              outline: "none !important",
            }}
            disabled={true}
            onChange={handleInputChange}
            name="name"
            value={data.name}
          />
          { errorName ?
          <FormFeedback style={{display: 'block'}} invalid>this field cannot be blank</FormFeedback> 
          : null}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="mailingAddress" sm={12} className=" label">
          Address
        </Label>
        <Col sm={12}>
          <input
            type="text"
            style={{
              ...inputs.textForm,
              outline: "none !important",
            }}
            disabled={true}
            onChange={handleInputChange}
            name="mailingAddress"
            value={data.mailing_address}
          />
           { errorMailingAddress ?
          <FormFeedback style={{display: 'block'}} invalid>this field cannot be blank</FormFeedback> 
          : null}
        </Col>
      </FormGroup>
      <FormGroup row className="no-gutters"> 
          <Label for="city" sm={6} className="org-labels label">
            City
          </Label>
          <Label for="state" sm={3} className="org-labels label">
            State
          </Label>
          <Label for="zip" sm={3} className="org-labels label">
            Zip
          </Label>
        <Col sm={6} className="mobile-label label">
          <Label for="city" sm={6}>
            City
          </Label>
        </Col>
        <Col sm={6}>
          <select
           className="select-non-editable"
            style={{
              ...select.textForm,
              outline: "none !important",
            }}
            disabled={true}
            onChange={handleInputChange}
            name="city"
          >
            {listCities}
          </select>
          { errorCity ?
          <FormFeedback style={{display: 'block'}} invalid>this field cannot be blank</FormFeedback> 
          : null}
        </Col>
        <Col sm={6} className="mobile-label label">
          <Label for="state" sm={6}>
            State
          </Label>
        </Col>
        <Col sm={3}>
          <select
            className="select-non-editable"
            style={{
              ...select.textForm,
              outline: "none !important",
            }}
            disabled={true}
            onChange={handleInputChange}
            name="state"
          >
            {listStates}
          </select>
          { errorState ?
          <FormFeedback style={{display: 'block'}} invalid>this field cannot be blank</FormFeedback> 
          : null}
        </Col>
        <Col sm={6} className="mobile-label label">
          <Label for="zip" sm={6}>
            Zip
          </Label>
        </Col>
        <Col sm={3}>
          <input
            type="text"
            style={{
              ...inputs.textForm,
              outline: "none !important",
            }}
            disabled={true}
            onChange={handleInputChange}
            name="zipcode"
            value={data.zipcode}
          />
          { errorZipcode ?
          <FormFeedback style={{display: 'block'}} invalid>this field cannot be blank</FormFeedback> 
          : null}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="tax" sm={12} className="label">
          Tax ID
        </Label>
        <Col sm={12}>
          <input
            type="password"
            style={{
              ...inputs.textForm,
              outline: "none !important",
            }}
            disabled={true}
            onChange={handleInputChange}
            onKeyUp=""
            name="taxId"
            value={tax}
          />
          { errorEin ?
            <FormFeedback style={{display: 'block'}} invalid>this field cannot be blank</FormFeedback> 
            : null}    
        </Col>
      </FormGroup>
      <FormGroup row className="mb-3">
        <Col sm={6} style={styles.stripeContainer}>

              
      
              {user.stripe_customer_id ? 
                <span style={styles.loginMsg}>Your Stripe account is connected.</span>
                :
                <span style={styles.errorMsg}>Your Stripe account is not connected yet.</span>
              }
                
                <br></br>
              {!user.stripe_customer_id ?
                <PrimaryButton  
                  text="Connect with Stripe" 
                  onClick= {()=>{obtainLink()}}
                  styleBtn={styles.btn}
                >        
                </PrimaryButton>
                :
                
                null
              }

        </Col>
      </FormGroup>
    </Form>
    }
    </div>
  );
};

export default Form1;
