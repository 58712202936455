export default {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        minHeight: '100vh',
        padding: '80px'
    },

    header: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },

    progressBar: {
        width: '80%',
        display: 'flex',
        marginTop: '10px',
        alignContent: 'center',
        justifyContent: 'center',
        maxHeight:'24px'
    },

    icon:{
        width: '24px',
        height: '24px',
    },

    logo:{
        width: '124px',
        height: '24px',
    }
}