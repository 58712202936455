import React from 'react'
import {
    Content
} from 'components/index'
import './style.css'


const Spinner = () => {
    return (
   
            <div className="circle">

            </div>


    );
}

export default Spinner;