import React, { useState, useEffect } from 'react'
import {
    Content, Spacer
} from 'components/index'
import { Redirect } from "react-router-dom";
import SignIn from 'views/Onboarding/SignIn'
import TertiaryButton from 'components/buttons/tertiaryButton/index'
import PrimaryButton from 'components/buttons/primaryButton/index'
import PrimaryLink from 'components/links/primaryLink/index'
import './style.css'
import accordionData from 'components/elements/accordion/data.js'
import Accordion from 'components/elements/accordion/index.js'
import bannerAsset from 'assets/Logo.svg'
import phone from 'assets/phone.png'
import TextLg from 'components/texts/Text-lg'
import TextHg from 'components/texts/Text-hg'
import inputs from 'style/inputs/text/index'
import 'style/inputs/autocomplete/index.css'
import imgData from './logosData'
import gp from 'assets/google-play.png'
import as from 'assets/apple.png'
import mobilePhone from 'assets/mobile-hero.png'
import desktopPhone from 'assets/desktop_phone.png'
import desktopPhoneTop from 'assets/desktop-phone-top.png'
import Footer from 'components/footer/index'


const Landing = () => {
    const text1 = "DonorPal allows people like you to easily & effortlessly make a positive impact on the world - one (or more) donation at a time."
    const [signIn, goSignIn] = useState(false)

    return (

        <Content classCss="container">
            {
                signIn ? <Redirect exact from="/" to="/sign-in" /> : null
            }
          <Content classCss="landing1">
            <Content classCss="header">
              <img src={bannerAsset} className="logo" alt="logo" />
              <span className="loginText" onClick= {()=>{goSignIn(true)}}>Organization sign in/up</span>
            </Content>
            <Spacer.Huge />
            <img src={desktopPhoneTop} className="desktopPhTop" alt="mobile phone 1" />
            <img src={desktopPhone} className="desktopPh" alt="mobile phone 2" />
            <img src={mobilePhone} className="mobilePh" alt="mobile phone 3" />
            <TextHg text='Meet donorpal.' classCss="title"/>
            <Spacer.Huge />
            <Content classCss="bodyText">
                <span className="bodyText-text">{text1}</span>
                <Spacer.Large />
                <Content alignCenter classCss="get-container-row">
                    <a className="link-btn" href="https://apps.apple.com/us/app/donorpal/id1601289306" target="_blank" rel="noreferrer">
                        <TertiaryButton
                            classCss="get-btn"
                            text={{small: 'Download on the', big: 'App Store'}}
                            imgUrl={as}
                        >
                        </TertiaryButton>
                    </a>
                    <a className="link-btn" href="https://play.google.com/store/apps/details?id=com.donorpal" target="_blank" rel="noreferrer">
                        <TertiaryButton
                            classCss="get-btn"
                            text={{small: 'Get it on', big: 'Google Play'}}
                            imgUrl={gp}
                        >
                        </TertiaryButton>
                    </a>
                </Content>
            </Content>
            <Spacer.Huge />
            <Content classCss="line">
            </Content>
          </Content>


          <Content classCss="landing2">
            <Content classCss="features-left">
                <span>features</span>
                <img src={phone} className="features-img" />
            </Content>
            <Content classCss="features-right">
                {accordionData.map((e, i) => (
                    <Accordion index={i+1} title={e.title} content={e.content} img={phone}/>
                ))}
            </Content>
          </Content>

          <Content classCss="landing4">
            <TextHg text='get donorpal' classCss="title3"/>
            <span className="donateText">tap. donate. done.</span>
            
            <Content alignCenter classCss="get-container">
                <a className="link-btn" href="https://apps.apple.com/us/app/donorpal/id1601289306" target="_blank" rel="noreferrer">
                    <TertiaryButton
                        classCss="get-btn"
                        text={{small: 'Download on the', big: 'App Store'}}
                        imgUrl={as}
                    >
                    </TertiaryButton>
                </a>
                <Spacer.Large />
                <a className="link-btn" href="https://play.google.com/store/apps/details?id=com.donorpal" target="_blank" rel="noreferrer">
                    <TertiaryButton
                        classCss="get-btn"
                        text={{small: 'Get it on', big: 'Google Play'}}
                        imgUrl={gp}
                    >
                    </TertiaryButton>
                </a>
                <Spacer.Huge />
            </Content>
            
            
          </Content>
          <Footer></Footer>
        </Content>
      );
}

export default Landing;
