import React, { useState } from 'react'
import styles from './style'

const PrimaryLink = ({ text, onClick }) => {
    
    return (
        <a
            style={{ 
                ...styles.link,
            }}
            onClick={onClick}
        >
            {text}
        </a>
    )
}

export default PrimaryLink