import colors from '../../../style/colors'

export default {
    button: {
        backgroundColor: colors.primary,
        color: colors.secondary,
        width: '302px',
        height: '56px', 
        paddingTop: '18',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '32px',
        borderWidth: '0px',
        fontWeight: '19px',
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.02em',
        cursor: 'pointer',
        fontFamily: 'inherit',
        // boxShadow: 'rgba(0,0,0,0.5) 5px 5px 5px',
        filter: "drop-shadow(0px 32px 64px rgba(12, 1, 254, 0.1)) drop-shadow(0px 2px 4px rgba(12, 1, 254, 0.1))"
    }
}