import colors from 'style/colors'

export default {
    textTransparent: {
        background: 'transparent',
        color: colors.primary,
        width: '480px',
        height: '62px', 
        justifyContent: 'center',
        textAlign: 'left',
        borderWidth: '0px',
        borderBottom:'1px solid #0C0030',
        borderBottom:'1px solid rgba(12, 0, 48, .3)',
        fontFamily: 'Circular Std',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
        outline: 'none'
    },
    option:{
        minHeight: '50px',
        fontFamily: 'Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        letterSpacing: '-0.03em',
        background: '#efedfc'
    },
    textForm: {
        background: 'transparent',
        color: colors.primary,
        cursor: 'pointer',
        width: '100%',
        height: '35px',
        paddingRight: '8px',
        justifyContent: 'center',
        textAlign: 'left',
        borderWidth: '0px',
        borderBottom:'1px solid #0C0030',
        borderBottom:'1px solid rgba(12, 0, 48, .3)',
        paddingBottom:'12px',
        fontFamily: 'Circular Std',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.03em',
    }
}

