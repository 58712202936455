import colors from '../../../style/colors'

export default {
    text: {
        color: colors.primary,
        textAlign: 'left',
        fontFamily: 'Circular Std',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '40px',
        lineHeight: '40px',
        letterSpacing: '-0.04em',
        textTransform: 'uppercase',
        height: 'fit-content'
    }
}