export default {
    container: {
        display: 'flex',
        flex: 1
    },

    row: {
        flexDirection: 'row',
    },

    column: {
        flexDirection: 'column',
    },
    
    justifyCenter: {
        justifyContent: 'center'
    },
    
    alignCenter: {
        alignItems: 'center'
    }
}