import React, { useState } from 'react'
import { Button } from 'reactstrap'
import styles from './style'

const PrimaryButton = ({ children, text, onClick, disabled, styleBtn = null, classCss }) => {
    const [touched, setTouched] = useState(false)

    const handleMouseUp = () => {
        setTimeout(() => {
            setTouched(false)
        }, 150)
    }

    const handleMouseDown = () => {
        setTouched(true)
    }

    return (
        <Button
            className={classCss}
            style={{ 
                ...styles.button,
                ...styleBtn,
                ...(touched ? { opacity: 0.5 } : {})             
            }}
            onClick={onClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            disabled={disabled?disabled:false}
        >
            {children || <span>{text}</span>}
        </Button>
    )
}

export default PrimaryButton