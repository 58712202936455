export default [
    {
      title: "We, Us, Our",
      text: "The nonprofit entity DonorPal",
    },
    {
      title: "the Site",
      text:
        "www.donorpal.com and all DonorPal mobile appsThe nonprofit entity DonorPal",
    },
    {
      title: "the Services",
      text: "The services offered through the Site",
    },
    {
      title: "DonorPal",
      text:
        "Just to clarify: when we are talking about the nonprofit entity called DonorPal, we say “we,” “us,” “our,” or just “DonorPal”. However, when we are discussing our website (www.DonorPal.com) or one of our mobile apps, we say the “Site”",
    },
    {
      title: "Support",
      text:
        "To Donate (to DonorPal.com), usually by making a gift in support of a Nonprofit",
    },
    {
      title: "Follow",
      text:
        "A connection you can make with a with a nonprofit, cause, or other Person on the Site",
    },
    {
      title: "Nonprofit",
      text:
        "The catch-all term we use for the various forms of charitable entities such as and other Donation-recipients listed on our Site",
    },
    {
      title: "International Nonprofit",
      text:
        "A Nonprofit that typically has not received a determination of its charitable status from the Internal Revenue Service (“IRS”), and is based outside the US.",
    },
    {
      title: "People/Person",
      text: "Anyone that uses the Site",
    },
    {
      title: "Giver/Supporter",
      text: "Anyone who uses the Site to Donate",
    },
    {
      title: "Cause",
      text:
        "This is how we categorize nonprofits focusing on different issue areas, such as “Environment“",
    },
    {
      title: "Terms",
      text: "This binding agreement, the DonorPal Terms of Use",
    },
    {
      title: "UGC",
      text:
        "User-Generated Content, any content created by you that you publicly post on the Site, including, for example, comments.",
    },
    {
      title: "Donate",
      text: "To send money to DonorPal through the Site",
    },
    {
      title: "Donation",
      text: "The actual money Donated",
    },
    {
      title: "Join",
      text:
        "Feature to signal that your donation was motivated by another donation",
    },
    {
      title: "One-Time Donation",
      text: "A Donation that occurs only once (obviously)",
    },
    {
      title: "Recurring Donation",
      text: "A donation you set up to repeat on a regular basis",
    },
    {
      title: "Distribution",
      text:
        "Payments made from DonorPal to a Nonprofit, either through a third-party nonprofit partner or directly, as a result of Donations.",
    },
    {
      title: "Direct Distribution",
      text:
        "Distributions made directly from DonorPal to a Nonprofit, without the use of a third-party nonprofit partner.",
    },
  ];
