import axios from "axios";

const apiBasePath = process.env.REACT_APP_DEV_API_URL_LOCAL

const OrganizationService = {
    fetchOrganization: async function(id) { 
        const response = await axios.get(`${apiBasePath}/org/${id}`);
        return response.data;
    },
    fetchDonations: async function(id) { 
        const response = await axios.get(`${apiBasePath}/donation/${id}/organization`);
        return response.data;
    },
    updateOrg: async function(data) { 
        const response = await axios.put(`${apiBasePath}/org/${data.id}/id`, data);
        return response.data;
    },



};

export default OrganizationService;
