import React from 'react'
import PropTypes from 'prop-types'


const ProgressBar = ({ 
    width,
    height,
    color,
    progress,
    style,
}) => {
    return (
        <div style={{
            ...style,
            width:width,
            height:height,
            background:'rgba(12, 0, 48, 0.2)',
            borderRadius:'2px'
        }}>
            
            <div style={{
                backgroundColor:color,
                width:progress,
                height:'100%',
                borderRadius:'2px'
            }}></div>
        </div>
    )
}

ProgressBar.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
    progress: PropTypes.string,
    style: PropTypes.object
}

ProgressBar.defaultProps = {
    width: '100%',
    height: '4px',
    color: '#001AFF',
    progress: '0%',
    style: {}
}

export default ProgressBar